const dev = {
    s3: {
        REGION: "us-east-1",
    },
    apiGateway: {
      URL: "https://api.alexwiss.com/"
    }
};


const prod = {
    s3: {
        REGION: "us-east-1",
    },
    apiGateway: {
      URL: "https://api.alexwiss.com/"
    }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
// Add common config values here
MAX_ATTACHMENT_SIZE: 5000000,
...config
};