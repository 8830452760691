import React, { Component } from "react";
import axios from 'axios';
import config from "../config.js";
import {
    Col,
    Grid,
    Panel,
    Row,
    Table,
    Glyphicon
} from "react-bootstrap";
import '../Home.css';

export default class NCAABPool extends Component {
    constructor(props) {
        super(props);
        document.title = "NCAAB Wins Pool";

        this.state = {
            isLoading: true,
            loadFailed: false,
            pool: [],
            teamDetail: [],
            selected: false
        };

        this.setSelected = this.setSelected.bind(this);
    }

    componentDidMount() {
        axios.get(config.apiGateway.URL + "ncaabpool")
        .then(res => {
            const pool = res.data;
            pool.sort(function(a,b) {
                if ( a.points < b.points ){
                  return 1;
                }
                if ( a.points > b.points ){
                  return -1;
                }
                return 0;
              } );
            this.setState({
              pool: pool,
              isLoading: false
            })
          }).catch(err => {
            this.setState({
              isLoading: false,
              loadFailed: true
            })
        })
    }

    setSelected(e) {
        const playerName = e.currentTarget.getAttribute("data-player")
        const player = this.state.pool.filter(function(data) {return data.primary_key === playerName})[0]
        this.setState({
            teamDetail: player.team_detail,
            selected: true
        })
    }   

    render() {

        return (
            <Grid>
                <Row>
                    <Col sm={12} md={6} lg={6}>
                        <Panel>
                            <Panel.Heading>NCAAB Wins Pool</Panel.Heading>
                            <Panel.Body>
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Wins</th>
                                    <th>Losses</th>
                                    <th>Points</th>
                                    <th>Win Percentage</th>
                                    <th>Games Played</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.pool.map((player) => {
                                    return <tr key={player.primary_key} data-player={player.primary_key} onClick={this.setSelected}>
                                        <td>{player.primary_key}</td>
                                        <td>{player.wins}</td>
                                        <td>{player.losses}</td>
                                        <td>{player.points}</td>
                                        <td>{(player.wins/player.games_played).toPrecision(3)}</td>
                                        <td>{player.games_played}</td>
                                    </tr>
                                })}
                                </tbody>
                            </Table>
                            {this.state.isLoading && <Glyphicon glyph="refresh" className="spinning" />}
                            {this.state.loadFailed && "Could not load league data. Please refresh the page."}
                            </Panel.Body>
                        </Panel>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <Panel>
                            <Panel.Heading>Team Detail</Panel.Heading>
                            <Panel.Body>
                            {this.state.selected ? <Table responsive>
                                <thead>
                                <tr>
                                    <th>Team</th>
                                    <th>W/L</th>
                                    <th>Wins</th>
                                    <th>Losses</th>
                                    <th>Points</th>
                                    <th>Win Percentage</th>
                                    <th>Games Played</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.teamDetail.map((team) => {
                                    return <tr key={team.team_name}>
                                        <td>{team.team_name.replace(/-/g, " ")}</td>
                                        <td>{team.win_loss}</td>
                                        <td>{team.wins}</td>
                                        <td>{team.losses}</td>
                                        <td>{team.points}</td>
                                        <td>{(team.wins/team.games_played).toPrecision(3)}</td>
                                        <td>{team.games_played}</td>
                                    </tr>
                                })}
                                </tbody>
                            </Table> : "Click a player to see their teams."}
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </Grid>    
        )
        
    }

}