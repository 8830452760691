import React, { Component } from 'react';
import './Home.css';
// import Sidebar from './components/sidebar'
import Introduction from './components/introduction'
// import About from './components/about'
// import Timeline from './components/timeline'

class Home extends Component {
  constructor(props){
    super(props);
    document.title = "Alex Wiss";
  }
  render() {
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
         	{/* <Sidebar></Sidebar> */}
				<div id="colorlib-main">
					<Introduction></Introduction>
					{/* <About></About>
					<Timeline></Timeline> */}
          	</div>
      	</div>
      </div>
    );
  }
}

export default Home;
